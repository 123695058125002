// @codekit-prepend "jquery/dist/jquery.js"
//- @codekit-prepend "fastclick/lib/fastclick.js"
//- @-prepend "page-accelerator/dist/page-accelerator.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.core.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.mediaQuery.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.box.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.keyboard.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.timer.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.imageLoader.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.motion.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.nest.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.touch.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.util.triggers.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.smoothScroll.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.abide.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.accordion.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.dropdownMenu.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.magellan.js"
//- @codekit-prepend "foundation-sites/dist/js/plugins/foundation.sticky.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.tabs.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.responsiveAccordionTabs.js"
// @codekit-prepend "foundation-sites/dist/js/plugins/foundation.reveal.js"
// @codekit-prepend "rrssb/js/rrssb.js"
// @codekit-prepend "select2/dist/js/select2.js"
// @codekit-prepend "darkmode-js/lib/darkmode-js.js"

// @codekit-prepend "@fancyapps/fancybox/dist/jquery.fancybox.js"
// @codekit-prepend "blockadblock/blockadblock.js"
// @codekit-prepend "aos/dist/aos.js"
// @codekit-append "maximize-select2-height-master/maximize-select2-height.js"
// @codekit-append "scrollup/index.js"
// @codekit-prepend "share-this/dist/share-this.js"
// @codekit-prepend "share-this/dist/sharers/twitter.js"
// @codekit-prepend "share-this/dist/sharers/email.js"
// @codekit-prepend "share-this/dist/sharers/facebook.js"
// @codekit-prepend "plyr/dist/plyr.js"
// @codekit-prepend "lazysizes/lazysizes.js"
// @codekit-prepend "rellax/rellax.js"
// @codekit-prepend "swiper/swiper-bundle.js"

//- @codekit-prepend "flying-pages/flying-pages.min.js"

// @codekit-append "reading-time-master/build/readingTime.min.js"
// @codekit-append "_nav.js"

// ======================================================================
// PAGE LOADING ANIMATION
// ======================================================================
$(window).on("load", function () {
  $(".loader-wrapper").fadeOut(500, function () {
    $("body").removeClass("no-scroll");
  });
});

// ======================================================================
// ██████  ██     ██  █████
// ██   ██ ██     ██ ██   ██
// ██████  ██  █  ██ ███████
// ██      ██ ███ ██ ██   ██
// ██       ███ ███  ██   ██
// ======================================================================

//- @-append "_pwabuilder-sw.js"

// -----  End of PWA  -----------------------------------

// pageAccelerator();

// window.addEventListener('load', () =>{
//    quicklink();
// });

$(document).foundation();

$(document).ready(function () {
  // =======================================================
  //  █████   ██████  ███████
  // ██   ██ ██    ██ ██
  // ███████ ██    ██ ███████
  // ██   ██ ██    ██      ██
  // ██   ██  ██████  ███████
  // =======================================================
  AOS.init({
    offset: 120,
    duration: 750,
    // easing: "ease",
    disable: "mobile",
    once: true,
    // startEvent: "load",
    // startEvent: "DOMContentLoaded"
  });

  $("[data-tabs]").on("change.zf.tabs", function () {
    // console.log('Those tabs sure did change!');
    AOS.refresh();
  });

  $(window).on("scroll", function () {
    // AOS.refresh();
  });
  // -----  End of AOS  ---------------------------------------------

  // =======================================================
  //  █████  ██████      ██████  ██       ██████   ██████ ██   ██
  // ██   ██ ██   ██     ██   ██ ██      ██    ██ ██      ██  ██
  // ███████ ██   ██     ██████  ██      ██    ██ ██      █████
  // ██   ██ ██   ██     ██   ██ ██      ██    ██ ██      ██  ██
  // ██   ██ ██████      ██████  ███████  ██████   ██████ ██   ██
  // =======================================================
  // Function called if AdBlock is not detected
  function adBlockNotDetected() {
    // alert('AdBlock is not enabled');
  }
  // Function called if AdBlock is detected
  function adBlockDetected() {
    alert(
      "\n*** AD BLOCKER DETECTED ***\n\nPlease disable your ad blocker or whitelist this site in your ad blocker settings to be able to view all of Mr. Rand’s work. Thank you!\n"
    );
  }

  // Recommended audit because AdBlock lock the file 'blockadblock.js'
  // If the file is not called, the variable does not exist 'blockAdBlock'
  // This means that AdBlock is present
  if (typeof blockAdBlock === "undefined") {
    adBlockDetected();
  } else {
    // blockAdBlock.onDetected(adBlockDetected);
    // blockAdBlock.onNotDetected(adBlockNotDetected);
    // and|or
    // blockAdBlock.on(true, adBlockDetected);
    // blockAdBlock.on(false, adBlockNotDetected);
    // and|or
    blockAdBlock.on(true, adBlockDetected).onNotDetected(adBlockNotDetected);
  }

  // Change the options
  blockAdBlock.setOption("checkOnLoad", false);
  // and|or
  // blockAdBlock.setOption({
  //     debug: false,
  //     checkOnLoad: true,
  //     resetOnEnd: false
  // });

  // -----  End of AD BLOCK  ---------------------------------------------

  // =======================================================
  // ███████  █████  ███    ██  ██████ ██    ██ ██████   ██████  ██   ██
  // ██      ██   ██ ████   ██ ██       ██  ██  ██   ██ ██    ██  ██ ██
  // █████   ███████ ██ ██  ██ ██        ████   ██████  ██    ██   ███
  // ██      ██   ██ ██  ██ ██ ██         ██    ██   ██ ██    ██  ██ ██
  // ██      ██   ██ ██   ████  ██████    ██    ██████   ██████  ██   ██
  // =======================================================
  $.fancybox.defaults.spinnerTpl = '<div class="fancybox-loading-rand"></div>';
  $("[data-fancybox]").fancybox({
    protect: true,
    idleTime: 1.5,
    // afterLoad: function(instance, current) {
    //     //     // this.title = $(this.element).find('img').attr('alt');
    //     // current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
    //     // $(".fancybox-image").attr("alt", current.opts.$orig.data('alt'));
    // }
    // afterLoad: function(instance, slide) {
    //     // slide.$slide.find('img').attr("alt", slide.opts.$orig.data('alt'));
    //     $(".fancybox-image").attr("alt", slide.opts.$orig.data('alt'));
    // }
  });

  // $('[data-fancybox]').fancybox({
  //     afterLoad: function(instance, current) {
  //         current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
  //     }
  // });
  // -----  End of FANCYBOX  ---------------------------------------------

  // =======================================================
  // ███████ ██   ██  █████  ██████  ███████     ████████ ██   ██ ██ ███████
  // ██      ██   ██ ██   ██ ██   ██ ██             ██    ██   ██ ██ ██
  // ███████ ███████ ███████ ██████  █████          ██    ███████ ██ ███████
  //      ██ ██   ██ ██   ██ ██   ██ ██             ██    ██   ██ ██      ██
  // ███████ ██   ██ ██   ██ ██   ██ ███████        ██    ██   ██ ██ ███████
  // =======================================================
  ShareThis({
    sharers: [ShareThisViaTwitter, ShareThisViaFacebook, ShareThisViaEmail],
  }).init();
  // -----  End of SHARE THIS  ---------------------------------------------

  // =======================================================
  // ██████  ██   ██    ██ ██████
  // ██   ██ ██    ██  ██  ██   ██
  // ██████  ██     ████   ██████
  // ██      ██      ██    ██   ██
  // ██      ███████ ██    ██   ██
  // =======================================================
  // plyr.setup();
  // const player = new Plyr('#player');
  const players = Array.from(document.querySelectorAll(".plyr-js")).map(
    (player) => new Plyr(player)
  );
  // const player = new Plyr('.js-player');
  // const players = Plyr.setup('.js-player', options);

  // -----  End of PLYR  ---------------------------------------------

  // =============================================================
  // =            REMOVE ANIMSITION LINK FROM BUTTONS            =
  // =============================================================
  // $(".external").click(function() {
  //     $(this).removeClass("animsition-link");
  // });

  // =====  End of REMOVE ANIMSITION LINK FROM BUTTONS  ============================================================

  // =============================================================
  // =            PREVENT RIGHT CLICK ON IMAGES            =
  // =============================================================
  $("img").bind("contextmenu", function (e) {
    return false;
  });

  // =====  End of PREVENT RIGHT CLICK ON IMAGES  ============================================================---

  // =======================================================
  // ██       █████  ███████ ██    ██ ███████ ██ ███████ ███████ ███████
  // ██      ██   ██    ███   ██  ██  ██      ██    ███  ██      ██
  // ██      ███████   ███     ████   ███████ ██   ███   █████   ███████
  // ██      ██   ██  ███       ██         ██ ██  ███    ██           ██
  // ███████ ██   ██ ███████    ██    ███████ ██ ███████ ███████ ███████
  // =======================================================
  window.lazySizesConfig = window.lazySizesConfig || {};
  // window.lazySizesConfig.init = false;
  lazySizesConfig.loadMode = 2;
  lazySizesConfig.expFactor = 4;
  lazySizesConfig.expand = 1000;
  //add simple support for background images:
  document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg");
    if (bg) {
      e.target.style.backgroundImage = "url(" + bg + ")";
    }
  });

  // -----  End of LAZYSIZES  ---------------------------------------------

  // =======================================================
  // AUTOFOCUS GOOGLE SITE SEARCH
  // =======================================================
  $("#searchModal").on("open.zf.reveal", function () {
    // document.getElementById('#gsc-i-id1').focus();
    // console.log('Reveal opened!');
    setTimeout(
      "document.getElementById('gsc-i-id1').focus().setAttribute('placeholder', 'Type to search…');",
      550
    );
  });

  // -----  End of AUTOFOCUS GOOGLE SITE SEARCH  ---------------------------------------------

  // =======================================================
  // TABS TITLE SCROLL TO TOP
  // =======================================================

  $(".tabs-title").on("click", function () {
    $(window).scrollTop(1);
  });

  // -----  End of TABS TITLE SCROLL TO TOP  ---------------------------------------------

  // =============================================================
  //  █████  ███    ██ ██ ███    ███ ███████ ██ ████████ ██  ██████  ███    ██
  // ██   ██ ████   ██ ██ ████  ████ ██      ██    ██    ██ ██    ██ ████   ██
  // ███████ ██ ██  ██ ██ ██ ████ ██ ███████ ██    ██    ██ ██    ██ ██ ██  ██
  // ██   ██ ██  ██ ██ ██ ██  ██  ██      ██ ██    ██    ██ ██    ██ ██  ██ ██
  // ██   ██ ██   ████ ██ ██      ██ ███████ ██    ██    ██  ██████  ██   ████
  // =============================================================

  // $("a").addClass("animsition-link");
  // $(".tabs-title a, a.cd-nav-trigger, li.go-back a, .my-gallery a, #writing-suggested-readings .button, .menu li a, #original-article-link").removeClass("animsition-link");
  // $(".animsition").animsition({
  //     inClass: "fade-in",
  //     outClass: "fade-out",
  //     inDuration: 1500,
  //     outDuration: 800,
  //     linkElement: ".animsition-link",
  //     // e.g. linkElement: "a:not([target="_blank"]):not([href^=#])"
  //     loading: true,
  //     loadingParentElement: "body", //animsition wrapper element
  //     loadingClass: "animsition-loading",
  //     loadingInner: "", // e.g "<img src="loading.svg" />"
  //     timeout: true,
  //     timeoutCountdown: 5000,
  //     onLoadEvent: true,
  //     browser: ["animation-duration", "-webkit-animation-duration"],
  //     // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
  //     // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
  //     overlay: false,
  //     overlayClass: "animsition-overlay-slide",
  //     overlayParentElement: "body",
  //     transition: function(url) {
  //         window.location.href = url;
  //     }
  // });

  // =====  End of ANIMSITION PAGE TRANSITION  =====

  // ===========================================================
  // ███████  ██████ ██████   ██████  ██      ██
  // ██      ██      ██   ██ ██    ██ ██      ██
  // ███████ ██      ██████  ██    ██ ██      ██
  //      ██ ██      ██   ██ ██    ██ ██      ██
  // ███████  ██████ ██   ██  ██████  ███████ ███████
  // ██    ██ ██████
  // ██    ██ ██   ██
  // ██    ██ ██████
  // ██    ██ ██
  //  ██████  ██
  // ============================================================
  $(function () {
    $.scrollUp({
      scrollName: "scrollUp", // Element ID
      topDistance: "800", // Distance from top before showing element (px)
      topSpeed: 300, // Speed back to top (ms)
      animation: "slide", // Fade, slide, none
      animationInSpeed: 500, // Animation in speed (ms)
      animationOutSpeed: 300, // Animation out speed (ms)
      scrollText: "Scroll to top", // Text for element
      activeOverlay: false, // Set CSS color to display scrollUp active point, e.g "#00FFFF"
      scrollImg: true,
      zIndex: 10,
    });
  });

  // =====  End of SCROLL UP  =====

  // =============================================================
  // ███████  ██████ ██████   ██████  ██      ██
  // ██      ██      ██   ██ ██    ██ ██      ██
  // ███████ ██      ██████  ██    ██ ██      ██
  //      ██ ██      ██   ██ ██    ██ ██      ██
  // ███████  ██████ ██   ██  ██████  ███████ ███████
  // ██████   ██████  ██     ██ ███    ██
  // ██   ██ ██    ██ ██     ██ ████   ██
  // ██   ██ ██    ██ ██  █  ██ ██ ██  ██
  // ██   ██ ██    ██ ██ ███ ██ ██  ██ ██
  // ██████   ██████   ███ ███  ██   ████
  // =============================================================

  $("#original-article-link").click(function () {
    $("html,body").animate(
      {
        scrollTop: $("#original-article").offset().top,
      },
      "1000"
    );
    return false;
  });

  // =====  End of SCROLL DOWN TO ORIGINAL ARTICLE  =====

  // =============================================================
  // ██████  ███████  █████  ██████  ██ ███    ██  ██████
  // ██   ██ ██      ██   ██ ██   ██ ██ ████   ██ ██
  // ██████  █████   ███████ ██   ██ ██ ██ ██  ██ ██   ███
  // ██   ██ ██      ██   ██ ██   ██ ██ ██  ██ ██ ██    ██
  // ██   ██ ███████ ██   ██ ██████  ██ ██   ████  ██████
  // ██████  ██████   ██████   ██████  ██████  ███████ ███████ ███████
  // ██   ██ ██   ██ ██    ██ ██       ██   ██ ██      ██      ██
  // ██████  ██████  ██    ██ ██   ███ ██████  █████   ███████ ███████
  // ██      ██   ██ ██    ██ ██    ██ ██   ██ ██           ██      ██
  // ██      ██   ██  ██████   ██████  ██   ██ ███████ ███████ ███████
  // =============================================================

  // $(window).load(function() {
  //     $(window).scroll(function() {
  //         var wintop = $(window).scrollTop(),
  //             docheight = $('body').height(),
  //             winheight = $(window).height();
  //         // console.log(wintop);
  //         var totalScroll = (wintop / (docheight - winheight)) * 100;
  //         // console.log("total scroll" + totalScroll);
  //         $(".progress-bar").css("width", totalScroll + "%");
  //     });
  //
  // });

  // FROM: https://alligator.io/js/progress-bar-javascript-css-variables/
  $(function () {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight",
      progress = document.querySelector(".progress-bar"),
      scroll;

    document.addEventListener("scroll", function () {
      scroll = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      progress.style.setProperty("--scroll", scroll + "%");
    });
  });

  // =====  End of READING PROGRESS INDICATOR  =====

  // =============================================================
  // ███████ ██      ██  ██████ ██   ██
  // ██      ██      ██ ██      ██  ██
  // ███████ ██      ██ ██      █████
  //      ██ ██      ██ ██      ██  ██
  // ███████ ███████ ██  ██████ ██   ██
  // ██████  █████  ██████   ██████  ██    ██ ███████ ███████ ██
  // ██      ██   ██ ██   ██ ██    ██ ██    ██ ██      ██      ██
  // ██      ███████ ██████  ██    ██ ██    ██ ███████ █████   ██
  // ██      ██   ██ ██   ██ ██    ██ ██    ██      ██ ██      ██
  // ██████ ██   ██ ██   ██  ██████   ██████  ███████ ███████ ███████
  // =============================================================
  // // =====
  // // Change element when it has scrolled into or out of view.
  // // From this jsFiddle: http://jsfiddle.net/tovic/vVaat/light/
  // // =====
  // function isScrolledIntoView(elem) {
  //     var $window = $(window),
  //         docViewTop = $window.scrollTop(),
  //         docViewBottom = docViewTop + $window.height(),
  //         elemTop = $(elem).offset().top,
  //         elemBottom = elemTop + $(elem).outerHeight(),
  //         threshold = 0;
  //     return ((elemBottom <= docViewBottom + threshold) && (elemTop >= docViewTop - threshold));
  // }
  // $(function() {
  //     $(window).on("scroll", function() {
  //         $('.slick-carousel').each(function() {
  //             if (isScrolledIntoView(this)) {
  //                 // $(this).addClass('active-carousel').find('.slick-list').attr('tabindex', -1).focus();
  //                 $(this).find('.slick-list').attr('tabindex', -1).focus();
  //                 $(this).find('.slick-slide').height('auto');
  //                 // $(this).find('.slick-list').height('auto');
  //                 $(this).slick('setOption', null, null, true);
  //                 // console.log("Carousel IN!");
  //                 // setTimeout(function() {
  //                 //     $(document).find('.slick-list').attr('tabindex', -1).focus();
  //                 // }, 100);
  //             } else {
  //                 // // $(this).removeClass('active-carousel').find('.slick-list').attr('tabindex', -1).blur();
  //                 $(this).find('.slick-list').attr('tabindex', -1).blur();
  //                 // // console.log("Carousel OUT!");
  //                 // // setTimeout(function() {
  //                 // //     $(document).find('.slick-list').attr('tabindex', -1).blur();
  //                 // // }, 100);
  //             }
  //         });
  //
  //     });
  //
  //     $(".slick-carousel").slick({
  //         infinite: true,
  //         speed: 300,
  //         centerMode: true,
  //         lazyLoad: 'ondemand',
  //         centerPadding: '180px',
  //         adaptiveHeight: true,
  //         dots: true,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         responsive: [
  //             {
  //                 breakpoint: 1025,
  //                 settings: {
  //                     centerPadding: '0px',
  //                     dots: false
  //                 }
  //         },
  //             {
  //                 breakpoint: 769,
  //                 settings: {
  //                     centerPadding: '0px',
  //                     dots: false
  //                 }
  //         },
  //             {
  //                 breakpoint: 375,
  //                 settings: {
  //                     dots: false,
  //                     centerPadding: '100px',
  //                 }
  //         }
  //         // You can unslick at a given breakpoint now by adding:
  //         // settings: "unslick"
  //         // instead of a settings object
  //       ]
  //     });
  // });

  // =====  End of SLICK CAROUSEL  =====

  // ============================================================
  // ███████ ██      ██  ██████ ██   ██ ██ ████████ ██    ██
  // ██      ██      ██ ██      ██  ██  ██    ██     ██  ██
  // █████   ██      ██ ██      █████   ██    ██      ████
  // ██      ██      ██ ██      ██  ██  ██    ██       ██
  // ██      ███████ ██  ██████ ██   ██ ██    ██       ██
  // ============================================================
  // $('.flickity-carousel').flickity({
  //     cellAlign: 'center',
  //     wrapAround: true,
  //     adaptiveHeight: true,
  //     lazyLoad: 3,
  // });

  // -----  End of FLICKITY  -----------------------------------

  // =============================================================
  // ██   ██ ███████  █████  ██████  ███████ ██████      ███████  ██████   ██████  ████████ ███████ ██████
  // ██   ██ ██      ██   ██ ██   ██ ██      ██   ██     ██      ██    ██ ██    ██    ██    ██      ██   ██
  // ███████ █████   ███████ ██   ██ █████   ██████      █████   ██    ██ ██    ██    ██    █████   ██████
  // ██   ██ ██      ██   ██ ██   ██ ██      ██   ██     ██      ██    ██ ██    ██    ██    ██      ██   ██
  // ██   ██ ███████ ██   ██ ██████  ███████ ██   ██     ██       ██████   ██████     ██    ███████ ██   ██
  // ██████   █████  ██████   █████  ██      ██       █████  ██   ██
  // ██   ██ ██   ██ ██   ██ ██   ██ ██      ██      ██   ██  ██ ██
  // ██████  ███████ ██████  ███████ ██      ██      ███████   ███
  // ██      ██   ██ ██   ██ ██   ██ ██      ██      ██   ██  ██ ██
  // ██      ██   ██ ██   ██ ██   ██ ███████ ███████ ██   ██ ██   ██
  // =============================================================
  // function scrollFooter(scrollY, heightFooter) {
  //     // console.log(scrollY);
  //     // console.log(heightFooter);
  //
  //     if (scrollY >= heightFooter) {
  //         $("footer").css({
  //             "bottom": "0px",
  //             "z-index": "2"
  //         });
  //     } else {
  //         $("footer").css({
  //             "bottom": "-" + heightFooter + "px",
  //             "z-index": "0"
  //         });
  //     }
  // }
  //
  // $(window)
  //     .load(function() {
  //         var windowHeight = $(window).height(),
  //             footerHeight = $("footer").height(),
  //             heightDocument = (windowHeight) + ($(".content").height());
  //
  //         // Definindo o tamanho dos elementos header e conteÃºdo
  //         // $(".wrapper-parallax").css({
  //         //     "margin-top": windowHeight + "px"
  //         // });
  //
  //         scrollFooter(window.scrollY, footerHeight);
  //
  //         // ao dar rolagem
  //         window.onscroll = function() {
  //             var scroll = window.scrollY;
  //
  //             scrollFooter(scroll, footerHeight);
  //         };
  //     });

  // function scrollFooter(scrollY, heightFooter) {
  //     console.log(scrollY);
  //     console.log(heightFooter);
  //
  //     if (scrollY >= heightFooter) {
  //         $('footer').css({
  //             'bottom': '0px'
  //         });
  //     } else {
  //         $('footer').css({
  //             'bottom': '-' + heightFooter + 'px'
  //         });
  //     }
  // }
  //
  // $(window).load(function() {
  //     var windowHeight = $(window).height(),
  //         footerHeight = $('footer').height(),
  //         heightDocument = (windowHeight) + ($('.content').height()) + ($('footer').height()) - 20;
  //
  //     // Definindo o tamanho do elemento pra animar
  //     $('#scroll-animate, #scroll-animate-main').css({
  //         'height': heightDocument + 'px'
  //     });
  //
  //     // Definindo o tamanho dos elementos header e conteúdo
  //     $('.imageHeader-full').css({
  //         'height': windowHeight + 'px',
  //         'line-height': windowHeight + 'px'
  //     });
  //
  //     $('.cd-main-content').css({
  //         'margin-top': windowHeight + 'px'
  //     });
  //
  //     scrollFooter(window.scrollY, footerHeight);
  //
  //     // ao dar rolagem
  //     window.onscroll = function() {
  //         var scroll = window.scrollY;
  //
  //         $('#scroll-animate-main').css({
  //             'top': '-' + scroll + 'px'
  //         });
  //
  //         $('.imageHeader-full').css({
  //             'background-position-y': 50 - (scroll * 100 / heightDocument) + '%'
  //         });
  //
  //         scrollFooter(scroll, footerHeight);
  //     };
  // });

  // =====  End of HEADER FOOTER PARALLAX  =====

  // ==========================================================
  // ██   ██ ███████  █████  ██████  ██████   ██████   ██████  ███    ███
  // ██   ██ ██      ██   ██ ██   ██ ██   ██ ██    ██ ██    ██ ████  ████
  // ███████ █████   ███████ ██   ██ ██████  ██    ██ ██    ██ ██ ████ ██
  // ██   ██ ██      ██   ██ ██   ██ ██   ██ ██    ██ ██    ██ ██  ██  ██
  // ██   ██ ███████ ██   ██ ██████  ██   ██  ██████   ██████  ██      ██
  // ===========================================================

  var lastScroll = 0;

  $("header").addClass("nav-down nav-up");

  $(window).scroll(function () {
    setTimeout(function () {
      //give them a second to finish scrolling before doing a check
      var scroll = $(window).scrollTop();
      if (scroll > lastScroll + 10) {
        $("header").removeClass("nav-down");
      } else if (scroll < lastScroll - 10) {
        $("header").addClass("nav-down");
      }
      lastScroll = scroll;
    }, 250);
  });

  // =====  End of HEADROOM  =====

  // =============================================================
  // ██████  ███████  █████  ██████  ██ ███    ██  ██████
  // ██   ██ ██      ██   ██ ██   ██ ██ ████   ██ ██
  // ██████  █████   ███████ ██   ██ ██ ██ ██  ██ ██   ███
  // ██   ██ ██      ██   ██ ██   ██ ██ ██  ██ ██ ██    ██
  // ██   ██ ███████ ██   ██ ██████  ██ ██   ████  ██████
  // ████████ ██ ███    ███ ███████
  //    ██    ██ ████  ████ ██
  //    ██    ██ ██ ████ ██ █████
  //    ██    ██ ██  ██  ██ ██
  //    ██    ██ ██      ██ ███████
  // =============================================================
  $(function () {
    $(".cd-main-content").readingTime({
      readingTimeTarget: $(".reading-time"),
      round: true,
      success: function (data) {
        console.log(data);
      },
      error: function (data) {
        console.log(data.error);
        $(".reading-time").remove();
      },
    });
  });
  // $(function() {
  //     var $article = $('.cd-main-content');
  //
  //     $article.readingTime({
  //         readingTimeAsNumber: true,
  //         readingTimeTarget: $article.find('.reading-time'),
  //         // wordCountTarget: $article.find('.word-count'),
  //         wordsPerMinute: 275,
  //         round: false,
  //         lang: 'en'
  //     });
  // });

  // =====  End of READING TIME ESTIMATOR  =====

  // =======================================================
  // ██████  ███████ ██      ██       █████  ██   ██
  // ██   ██ ██      ██      ██      ██   ██  ██ ██
  // ██████  █████   ██      ██      ███████   ███
  // ██   ██ ██      ██      ██      ██   ██  ██ ██
  // ██   ██ ███████ ███████ ███████ ██   ██ ██   ██
  // =======================================================
  var rellax = new Rellax(".rellax", {
    // speed: -2,
    center: true,
    // wrapper: '.rellax-wrapper',
    // round: true,
    // vertical: true,
    // horizontal: false
  });

  // -----  End of RELLAX  ---------------------------------------------

  // =======================================================
  // ██████   ██████   ██████     ██████  ███████  █████  ██████  ██    ██
  // ██   ██ ██    ██ ██          ██   ██ ██      ██   ██ ██   ██  ██  ██
  // ██   ██ ██    ██ ██          ██████  █████   ███████ ██   ██   ████
  // ██   ██ ██    ██ ██          ██   ██ ██      ██   ██ ██   ██    ██
  // ██████   ██████   ██████     ██   ██ ███████ ██   ██ ██████     ██
  // ███████ ███    ██ ██████
  // ██      ████   ██ ██   ██
  // █████   ██ ██  ██ ██   ██
  // ██      ██  ██ ██ ██   ██
  // ███████ ██   ████ ██████
  // =======================================================
});

// =======================================================
//  ██████  ██    ██ ██  ██████ ██   ██ ██      ██ ███    ██ ██   ██
// ██    ██ ██    ██ ██ ██      ██  ██  ██      ██ ████   ██ ██  ██
// ██    ██ ██    ██ ██ ██      █████   ██      ██ ██ ██  ██ █████
// ██ ▄▄ ██ ██    ██ ██ ██      ██  ██  ██      ██ ██  ██ ██ ██  ██
//  ██████   ██████  ██  ██████ ██   ██ ███████ ██ ██   ████ ██   ██
//     ▀▀
// =======================================================

// window.addEventListener('load', () =>{
//    quicklink();
// });
// quicklink();
// import quicklink from "quicklink/dist/quicklink.mjs";
// quicklink();

// -----  End of QUICKLINK  ---------------------------------------------

// =====================================================================================
// ███████ ██     ██ ██    ██ ██████
// ██      ██     ██ ██    ██ ██   ██
// ███████ ██  █  ██ ██    ██ ██████
//      ██ ██ ███ ██ ██    ██ ██
// ███████  ███ ███   ██████  ██
// =====================================================================================

// import Swup from 'swup';
// var swupOptions = {
//     LINK_SELECTOR: 'a[class*="swup"]',
//     animationSelector: '[class*="swup-transition-"]',
//     pageClassPrefix: false,
// };
// var swup = new Swup(swupOptions);
//
// swup.on('contentReplaced', function () {
//     navInit();
// });

// -----  End of SWUP  ---------------------------------------------

// ===================================================================================
// ██████   █████  ██████  ██   ██     ███    ███  ██████  ██████  ███████
// ██   ██ ██   ██ ██   ██ ██  ██      ████  ████ ██    ██ ██   ██ ██
// ██   ██ ███████ ██████  █████       ██ ████ ██ ██    ██ ██   ██ █████
// ██   ██ ██   ██ ██   ██ ██  ██      ██  ██  ██ ██    ██ ██   ██ ██
// ██████  ██   ██ ██   ██ ██   ██     ██      ██  ██████  ██████  ███████
// ===================================================================================
var options = {
  // bottom: '32px', // default: '32px'
  // right: '32px', // default: '32px'
  // left: 'unset', // default: 'unset'
  // time: '0.3s', // default: '0.3s'
  // mixColor: '#fff', // default: '#fff'
  // backgroundColor: '#fff',  // default: '#fff'
  buttonColorDark: "#121212", // default: '#100f2c'
  // buttonColorLight: '#fff', // default: '#fff'
  // saveInCookies: true, // default: true,
  label: "&#9680;", // default: '🌓'
  // autoMatchOsTheme: true // default: true
};

const darkmode = new Darkmode(options);
darkmode.showWidget();
// -----  End of DARK MODE  -----------------------------------

// ===================================================================================
// ███████ ██     ██ ██ ██████  ███████ ██████
// ██      ██     ██ ██ ██   ██ ██      ██   ██
// ███████ ██  █  ██ ██ ██████  █████   ██████
//      ██ ██ ███ ██ ██ ██      ██      ██   ██
// ███████  ███ ███  ██ ██      ███████ ██   ██
//  ██████  █████  ██████   ██████  ██    ██ ███████ ███████ ██
// ██      ██   ██ ██   ██ ██    ██ ██    ██ ██      ██      ██
// ██      ███████ ██████  ██    ██ ██    ██ ███████ █████   ██
// ██      ██   ██ ██   ██ ██    ██ ██    ██      ██ ██      ██
//  ██████ ██   ██ ██   ██  ██████   ██████  ███████ ███████ ███████
// ==================================================================================
window.onload = function () {
  const mySwiper = new Swiper(".swiper", {
    // loop: true,
    // loopedSlides:3,
    // initialSlide: 0,
    // watchSlidesVisibility: true,
    // updateOnWindowResize: true,
    // observer: true,
    autoHeight: true,
    rewind: true,
    centeredSlides: true,
    // grabCursor: true,
    // centerInsufficientSlides: true,
    // watchOverflow: true,
    // preloadImages: false,
    // lazy: {
    //     loadPrevNext: true,
    //     loadPrevNextAmount: 7,
    // },
    // loadOnTransitionStart: true,
    breakpoints: {
      375: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      1024: {
        spaceBetween: 40,
        slidesPerView: 3,
        // slidesPerGroup: 1,
        // loopedSlides: 2,
        // slidesOffsetBefore: 40,
        // slidesOffsetAfter: 40,
      },
    },
    keyboard: {
      enabled: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
};
// -----  End of SWIPER CAROUSEL  -----------------------------------

// ===================================================================================
//
// ███████ ███    ███  ██████   ██████  ████████ ██   ██     ███████  ██████ ██████   ██████  ██      ██
// ██      ████  ████ ██    ██ ██    ██    ██    ██   ██     ██      ██      ██   ██ ██    ██ ██      ██
// ███████ ██ ████ ██ ██    ██ ██    ██    ██    ███████     ███████ ██      ██████  ██    ██ ██      ██
//      ██ ██  ██  ██ ██    ██ ██    ██    ██    ██   ██          ██ ██      ██   ██ ██    ██ ██      ██
// ███████ ██      ██  ██████   ██████     ██    ██   ██     ███████  ██████ ██   ██  ██████  ███████ ███████
// ===================================================================================

// var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var math = {
//   lerp: function lerp(a, b, n) {
//     return (1 - n) * a + n * b;
//   },
//   norm: function norm(value, min, max) {
//     return (value - min) / (max - min);
//   } };
//
//
// var config = {
//   height: window.innerHeight,
//   width: window.innerWidth };var
//
//
// Smooth = function () {
//   function Smooth() {_classCallCheck(this, Smooth);
//     this.bindMethods();
//
//     this.data = {
//       ease: 0.1,
//       current: 0,
//       last: 0 };
//
//
//     this.dom = {
//       el: document.querySelector('[data-scroll]'),
//       content: document.querySelector('[data-scroll-content]') };
//
//
//     this.rAF = null;
//
//     this.init();
//   }_createClass(Smooth, [{ key: 'bindMethods', value: function bindMethods()
//
//     {var _this = this;
//       ['scroll', 'run', 'resize'].
//       forEach(function (fn) {return _this[fn] = _this[fn].bind(_this);});
//     } }, { key: 'setStyles', value: function setStyles()
//
//     {
//       this.dom.el.style.position = 'fixed';
//       this.dom.el.style.top = 0;
//       this.dom.el.style.left = 0;
//       this.dom.el.style.height = '100%';
//       this.dom.el.style.width = '100%';
//       this.dom.el.style.overflow = 'hidden';
//     } }, { key: 'setHeight', value: function setHeight()
//
//     {
//       document.body.style.height = this.dom.content.offsetHeight + 'px';
//     } }, { key: 'resize', value: function resize()
//
//     {
//       this.setHeight();
//       this.scroll();
//     } }, { key: 'preload', value: function preload()
//
//     {var _this2 = this;
//       this.dom.content, function (instance) {
//         _this2.setHeight();
//       };
//     } }, { key: 'scroll', value: function scroll()
//
//     {
//       this.data.current = window.scrollY;
//     } }, { key: 'run', value: function run()
//
//     {
//       this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease);
//       this.data.last = Math.floor(this.data.last * 100) / 100;
//
//       var diff = this.data.current - this.data.last;
//       var acc = diff / config.width;
//       var velo = +acc;
//       var skew = velo * 1;
//
//       this.dom.content.style.transform = 'translate3d(0, -' + this.data.last.toFixed(2) + 'px, 0) skewY(' + skew + 'deg)';
//
//       this.requestAnimationFrame();
//     } }, { key: 'on', value: function on()
//
//     {var requestAnimationFrame = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
//       this.setStyles();
//       this.setHeight();
//       this.addEvents();
//
//       requestAnimationFrame && this.requestAnimationFrame();
//     } }, { key: 'off', value: function off()
//
//     {var cancelAnimationFrame = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
//       cancelAnimationFrame && this.cancelAnimationFrame();
//
//       this.removeEvents();
//     } }, { key: 'requestAnimationFrame', value: function (_requestAnimationFrame) {function requestAnimationFrame() {return _requestAnimationFrame.apply(this, arguments);}requestAnimationFrame.toString = function () {return _requestAnimationFrame.toString();};return requestAnimationFrame;}(function ()
//
//     {
//       this.rAF = requestAnimationFrame(this.run);
//     }) }, { key: 'cancelAnimationFrame', value: function (_cancelAnimationFrame) {function cancelAnimationFrame() {return _cancelAnimationFrame.apply(this, arguments);}cancelAnimationFrame.toString = function () {return _cancelAnimationFrame.toString();};return cancelAnimationFrame;}(function ()
//
//     {
//       cancelAnimationFrame(this.rAF);
//     }) }, { key: 'destroy', value: function destroy()
//
//     {
//       document.body.style.height = '';
//
//       this.data = null;
//
//       this.removeEvents();
//       this.cancelAnimationFrame();
//     } }, { key: 'resize', value: function resize()
//
//     {
//       this.setHeight();
//     } }, { key: 'addEvents', value: function addEvents()
//
//     {
//       window.addEventListener('resize', this.resize, { passive: true });
//       window.addEventListener('scroll', this.scroll, { passive: true });
//     } }, { key: 'removeEvents', value: function removeEvents()
//
//     {
//       window.removeEventListener('resize', this.resize, { passive: true });
//       window.removeEventListener('scroll', this.scroll, { passive: true });
//     } }, { key: 'init', value: function init()
//
//     {
//       this.preload();
//       this.on();
//     } }]);return Smooth;}();
//
//
// var smooth = new Smooth();

// -----  End of SMOOTH SCROLL  -----------------------------------
