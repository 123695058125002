$("#select-work-main").select2({
  data: [
    {
      id: "",
      text: "",
    },
    {
      id: "index",
      text: "VIEW ALL →",
      thumbnail: "",
    },
    {
      text: "Project Type",
      children: [
        {
          id: "Book-Covers",
          text: "Book Covers",
          thumbnail:
            "https://assets.paulrand.design/Works/_Books/Covers%20Only/1945%20Leave%20Cancelled/Web/Leave%20Cancelled%2C%20front-200.jpg",
        },
        {
          id: "Designed-and-Contributed-to",
          text: "Books Designed and/or Contributed to by Rand",
          thumbnail:
            "https://assets.paulrand.design/Works/_Books/Designed%20or%20Contributed%20to%20by%20Rand/1945%20The%20Tables%20of%20the%20Law/Web/Tables%20of%20the%20Law%2C%20front-200.jpg",
        },
        {
          id: "Logos",
          text: "Logos",
          thumbnail:
            "https://assets.paulrand.design/Works/IBM/Logos/IBM-8-bar.svg",
        },
        {
          id: "Logo-Presentation-Books",
          text: "Logo Presentation Books",
          thumbnail:
            "https://assets.paulrand.design/Works/NeXT/Logo%20Book/Web/NeXT%2001-200.jpg",
        },
        {
          id: "Painting",
          text: "Painting",
          thumbnail:
            "https://assets.paulrand.design/Works/_Paintings/Fish%20Bowl%20and%20Vase%201955-05-02/Web/Fish%20Bowl%20and%20Vase%201955-05-02-200.jpg",
        },
        {
          id: "Photography",
          text: "Photography",
          thumbnail:
            "https://assets.paulrand.design/Works/_Photography/Web/Ship%20Boards-200.jpg",
        },
        {
          id: "Posters",
          text: "Posters",
          thumbnail:
            "https://assets.paulrand.design/Works/_Posters/Oil%20Lamp/Web/Oil%20Lamp%20poster%2001-200.jpg",
        },
      ],
    },
    {
      text: "A",
      children: [
        {
          id: "ABC",
          text: "ABC (American Broadcasting Company)",
          thumbnail:
            "https://assets.paulrand.design/Works/American%20Broadcasting%20Company%20%28ABC%29/ABC-1962.svg",
        },
        {
          id: "Accent-Software-International",
          text: "Accent Software International",
          thumbnail:
            "https://assets.paulrand.design/Works/Accent%20Software%20International/Accent-Software-International-1994.svg",
        },
        {
          id: "ACI",
          text: "ACI (Advertising Composition Inc)",
          thumbnail:
            "https://assets.paulrand.design/Works/Advertising%20Composition%20Inc%20%28ACI%29/Web/ACI%20Christmas%20Card.jpg",
        },
        {
          id: "AdStar",
          text: "AdStar",
          thumbnail:
            "https://assets.paulrand.design/Works/AdStar/AdStar-Advanced-Storage-and-Retrieval-1992.svg",
        },
        {
          id: "Advertising-Typographers-Association-of-America",
          text: "Advertising Typographers Association of America- Type Talks",
          thumbnail:
            "https://assets.paulrand.design/Works/Advertising%20Typographers%20Association%20of%20America-%20Type%20Talks/Type%20Talks%201964/Web/Advertising%20Typographers%20Association%20of%20America-%20Type%20Talks%201964.jpg",
        },
        {
          id: "AIGA",
          text: "AIGA",
          thumbnail:
            "https://assets.paulrand.design/Works/AIGA/AIGA-1982-color-not-used.svg",
        },
        {
          id: "Airwick",
          text: "Airwick",
          thumbnail:
            "https://assets.paulrand.design/Works/Airwick/Air-wick.svg",
        },
        {
          id: "American-Advertising-Guild",
          text: "American Advertising Guild",
          thumbnail:
            "https://assets.paulrand.design/Works/American%20Advertising%20Guild/Web/American%20Advertising%20Guild%20Announcement%20Card%201941-200.jpg",
        },
        {
          id: "American-Express",
          text: "American Express",
          thumbnail:
            "https://assets.paulrand.design/Works/American%20Express/American-Express-v1-1994.svg",
        },
        {
          id: "American-Printer-Magazine",
          text: "American Printer Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/American%20Printer%20Magazine/Web/American%20Printer%20Magazine%20vol%2097%20no%202%20November%201933-200.jpg",
        },
        {
          id: "Ancient-Age-Whiskey",
          text: "Ancient Age Whiskey",
          thumbnail:
            "https://assets.paulrand.design/Works/Ancient%20Age%20Whiskey/Ancient-Age.svg",
        },
        {
          id: "Anton-Maix-Fabrics",
          text: "Anton Maix Fabrics",
          thumbnail:
            "https://assets.paulrand.design/Works/Anton%20Maix%20Fabrics/Abacus%20Fabric/Web/Anton%20Maix-%20Abacus%20Fabric%2000-200.jpg",
        },
        {
          id: "Apparel-Arts",
          text: "Apparel Arts",
          thumbnail:
            "https://assets.paulrand.design/Works/Apparel%20Arts/vol%209%20no%201%20July-August%201938/Web/Apparel%20Arts%20Magazine%20vol%209%20no%201%20July-August%201938%2001-200.jpg",
        },
        {
          id: "Architectural-Forum",
          text: "Architectural Forum",
          thumbnail:
            "https://assets.paulrand.design/Works/Architectural%20Forum/1945%20March/Web/Architectural%20Forum%2C%201945%20March-200.jpg",
        },
        {
          id: "Associated-Merchandising-Corp",
          text: "Associated Merchandising Corp",
          thumbnail:
            "https://assets.paulrand.design/Works/Associated%20Merchandising%20Corp/Web/Associated%20Merchandising%20Corp%20Ad-%20Art%20Directors%20show%2C%201937%2001-200.jpg",
        },
        {
          id: "Art-Directors-Club-of-New-York",
          text: "Art Director’s Club of New York",
          thumbnail:
            "https://assets.paulrand.design/Works/Art%20Directors%20Club%20of%20New%20York/1988%20Call%20for%20Entries/Web/Art%20Directors%20Club%20of%20New%20York%20Call%20for%20Entries%20Postcard%201988-200.jpg",
        },
        {
          id: "Atlas-Crankshaft",
          text: "Atlas Crankshaft",
          thumbnail:
            "https://assets.paulrand.design/Works/Atlas%20Crankshaft/Atlas-Crankshaft-1964.svg",
        },
        {
          id: "Autocar-Corporation",
          text: "Autocar Corporation",
          thumbnail:
            "https://assets.paulrand.design/Works/Autocar%20Corporation/Mechanized%20Mules%20of%20Victory/Web/Mechanized%20Mules%20of%20Victory%2001-200.jpg",
        },
        {
          id: "Ayerst-McKenna-Harrison-Pharmaceutical",
          text: "Ayerst, McKenna, Harrison Pharmaceutical",
          thumbnail:
            "https://assets.paulrand.design/Works/Ayerst%20McKenna%20Harrison%20Pharmaceutical/Tomectin%20Folder%20v1%201948/Web/Tomectin%20Folder%201948-200.jpg",
        },
      ],
    },
    {
      text: "B",
      children: [
        {
          id: "Bab-O",
          text: "Bab-O",
          thumbnail:
            "https://assets.paulrand.design/Works/Bab-O/Bab-O-1951.svg",
        },
        {
          id: "Benjamin-Franklin-200th-Anniversary",
          text: "Benjamin Franklin 200th Anniversary",
          thumbnail:
            "https://assets.paulrand.design/Works/Benjamin%20Franklin%20200th%20Anniversary/Benjamin-Franklin-200th-Anniversary-Celebration-1990.svg",
        },
        {
          id: "Blatz-Beer",
          text: "Blatz Beer",
          thumbnail:
            "https://assets.paulrand.design/Works/Blatz%20Beer/Web/Blatz%20Continental%20Special-200.jpg",
        },
        {
          id: "Borzoi",
          text: "Borzoi",
          thumbnail:
            "https://assets.paulrand.design/Works/Borzoi/Borzoi-1945.svg",
        },
        {
          id: "Bureau-of-Indian-Affairs",
          text: "Bureau of Indian Affairs",
          thumbnail:
            "https://assets.paulrand.design/Works/Bureau%20of%20Indian%20Affairs/US-Department-of-the-Interior-Bureau-of-Indian-Affairs-1968.svg",
        },
      ],
    },
    {
      text: "C",
      children: [
        {
          id: "Cabbages-Kings-Catering",
          text: "Cabbages & Kings Catering",
          thumbnail:
            "https://assets.paulrand.design/Works/Cabbages%20%26%20Kings%20Catering/Cabbages-and-Kings-Catering-1980.svg",
        },
        {
          id: "Century-Lighting",
          text: "Century Lighting",
          thumbnail:
            "https://assets.paulrand.design/Works/Century%20Lighting/Century-Lighting-1949.svg",
        },
        {
          id: "CIO-Chorus",
          text: "CIO Chorus",
          thumbnail:
            "https://assets.paulrand.design/Works/CIO%20Chorus/Web/CIO%20Chorus%2001-200.jpg",
        },
        {
          id: "Colorforms",
          text: "Colorforms",
          thumbnail:
            "https://assets.paulrand.design/Works/Colorforms/Colorforms-1959.svg",
        },
        {
          id: "Color-Lab",
          text: "Color Lab",
          thumbnail:
            "https://assets.paulrand.design/Works/Color%20Lab/ColorLab.svg",
        },
        {
          id: "Columbus-Indiana-Visitors-Bureau",
          text: "Columbus Indiana Visitors Bureau",
          thumbnail:
            "https://assets.paulrand.design/Works/Columbus%20Indiana%20Visitors%20Bureau/Columbus-Indiana-Visitors-Center-1973.svg",
        },
        {
          id: "Computer-Impressions",
          text: "Computer Impressions",
          thumbnail:
            "https://assets.paulrand.design/Works/Computer%20Impressions/Computer-Impressions-1995.svg",
        },
        {
          id: "Conde-Nast",
          text: "Conde Nast",
          thumbnail:
            "https://assets.paulrand.design/Works/Conde%20Nast/Web/Conde%20Nast%2C%20Glamour%20Magazine%2C%20September%201956-200.jpg",
        },
        {
          id: "Connecticut-Art-Directors-Club",
          text: "Connecticut Art Directors Club",
          thumbnail:
            "https://assets.paulrand.design/Works/Connecticut%20Art%20Directors%20Club/Connecticut-Art-Directors-Club-1986.svg",
        },
        {
          id: "Consolidated-Cigar-Corporation",
          text: "Consolidated Cigar Corporation",
          thumbnail:
            "https://assets.paulrand.design/Works/Consolidated%20Cigar%20Corporation/Consolidated-Cigar-Corporation-1959.svg",
        },
        {
          id: "Container-Corporation-of-America",
          text: "Container Corporation of America",
          thumbnail:
            "https://assets.paulrand.design/Works/Container%20Corporation%20of%20America/Web/Container%20Corporation%20of%20America%2001-200.jpg",
        },
        {
          id: "Cooks-Champagne",
          text: "Cooks Champagne",
          thumbnail:
            "https://assets.paulrand.design/Works/Cooks/Web/Cooks-200.jpg",
        },
        {
          id: "Coordinator-of-Inter-American-Affairs",
          text: "Coordinator of Inter American Affairs",
          thumbnail:
            "https://assets.paulrand.design/Works/Coordinator%20of%20Inter%20American%20Affairs/Web/Coordinator%20of%20Inter%20American%20Affairs%2C%20brochure%201943%2001-200.jpg",
        },
        {
          id: "Coronet-Brandy",
          text: "Coronet Brandy",
          thumbnail:
            "https://assets.paulrand.design/Works/Coronet%20Brandy/Coronet-Snifter-Man-1942.svg",
        },
        {
          id: "Country-Club-Ice-Cream",
          text: "Country Club Ice Cream",
          thumbnail:
            "https://assets.paulrand.design/Works/Country%20Club%20Ice%20Cream/Country-Club-Ice-Cream-Company-v1.svg",
        },
        {
          id: "Creative-Media-Center",
          text: "Creative Media Center",
          thumbnail:
            "https://assets.paulrand.design/Works/Creative%20Media%20Center/Creative-Media-Center-1994.svg",
        },
        {
          id: "Cresta-Blanca-Wines",
          text: "Cresta Blanca Wines",
          thumbnail:
            "https://assets.paulrand.design/Works/Cresta%20Blanca%20Wines/Cresta-Blanca-Wine-Co-1942.svg",
        },
        {
          id: "Cummins",
          text: "Cummins",
          thumbnail:
            "https://assets.paulrand.design/Works/Cummins/Cummins-1962.svg",
        },
      ],
    },
    {
      text: "D",
      children: [
        {
          id: "Direction-Magazine",
          text: "Direction Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Direction%20Magazine/vol%201%20no%209%20November%20December%201938/Web/Direction%20Magazine%20vol%201%20no%209%20November%20December%201938%2001-200.jpg",
        },
        {
          id: "Disney-Hats",
          text: "Disney Hats",
          thumbnail:
            "https://assets.paulrand.design/Works/Disney%20Hats/Web/Disney%20Hats%2004-200.jpg",
        },
        {
          id: "Doug-Evans-Partners",
          text: "Doug Evans + Partners",
          thumbnail:
            "https://assets.paulrand.design/Works/Doug%20Evans%20%2B%20Partners/Doug-Evans-and-Partners-1996.svg",
        },
        {
          id: "Dubonnet",
          text: "Dubonnet",
          thumbnail:
            "https://assets.paulrand.design/Works/Dubonnet/Dubonnet-1942.svg",
        },
        {
          id: "Dubouchett-Cordials",
          text: "Dubouchett Cordials",
          thumbnail:
            "https://assets.paulrand.design/Works/Dubouchett%20Cordials/DuBouchett-1949.svg",
        },
        {
          id: "Dunhill",
          text: "Dunhill",
          thumbnail:
            "https://assets.paulrand.design/Works/Dunhill/Dunhill-Clothiers-1947.svg",
        },
        {
          id: "Dupont-Corporation",
          text: "Dupont Corporation",
          thumbnail:
            "https://assets.paulrand.design/Works/Dupont%20Corporation/Web/Du%20Pont%2001-200.jpg",
        },
      ],
    },
    {
      text: "E",
      children: [
        {
          id: "El-Producto",
          text: "El Producto",
          thumbnail:
            "https://assets.paulrand.design/Works/El%20Producto/El-Producto-Cigar-Man.svg",
        },
        {
          id: "Elliot-Noyes-Associates",
          text: "Elliot Noyes & Associates",
          thumbnail:
            "https://assets.paulrand.design/Works/Elliot%20Noyes%20%26%20Associates/Elliot-Noyes-and-Associates-1959.svg",
        },
        {
          id: "Emerson-Electronic-Radio",
          text: "Emerson Electronic Radio",
          thumbnail:
            "https://assets.paulrand.design/Works/Emerson%20Electronic%20Radio/Web/Emerson%2001-200.jpg",
        },
        {
          id: "Education-First",
          text: "Education First",
          thumbnail:
            "https://assets.paulrand.design/Works/Education%20First/Education-First-1993.svg",
        },
        {
          id: "Enron",
          text: "Enron",
          thumbnail:
            "https://assets.paulrand.design/Works/Enron/Enron-1996.svg",
        },
        {
          id: "Esquire",
          text: "Esquire",
          thumbnail:
            "https://assets.paulrand.design/Works/Esquire/Esquire-Magazine-1938.svg",
        },
      ],
    },
    {
      text: "F",
      children: [
        {
          id: "Flair-Magazine",
          text: "Flair Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Flair%20Magazine/Web/Flair%20Magazine%2001-200.jpg",
        },
        {
          id: "Ford",
          text: "Ford",
          thumbnail:
            "https://assets.paulrand.design/Works/Ford/Ford-Motor-Company-1966-not-used.svg",
        },
        {
          id: "Fortune-Magazine",
          text: "Fortune Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Fortune%20Magazine/Web/Fortune%20Magazine%20cover%20study%2001-200.jpg",
        },
        {
          id: "Frigiking",
          text: "Frigiking",
          thumbnail:
            "https://assets.paulrand.design/Works/Frigiking/Frigiking.svg",
        },
      ],
    },
    {
      text: "G",
      children: [
        {
          id: "Gentry-Living-Color",
          text: "Gentry Living Color",
          thumbnail:
            "https://assets.paulrand.design/Works/Gentry%20Living%20Color/Gentry-Living-Color-1993.svg",
        },
        {
          id: "Gibsons-Diamond-8",
          text: "Gibsons Diamond 8",
          thumbnail:
            "https://assets.paulrand.design/Works/Gibson%27s%20Diamond%208/Gibson-Diamond-Co.svg",
        },
        {
          id: "Glass-Packer",
          text: "Glass Packer",
          thumbnail:
            "https://assets.paulrand.design/Works/Glass%20Packer/Glass-Packer.svg",
        },
        {
          id: "Graphic-Arts-Production-Yearbook",
          text: "Graphic Arts Production Yearbook",
          thumbnail:
            "https://assets.paulrand.design/Works/Graphic%20Arts%20Production%20Yearbook/Web/Graphic%20Arts%20Production%20Yearbook-200.jpg",
        },
      ],
    },
    {
      text: "H",
      children: [
        {
          id: "Hamburg-American-Line",
          text: "Hamburg American Line",
          thumbnail:
            "https://assets.paulrand.design/Works/Hamburg%20American%20Line/Web/Hamburg-American%20Line%20Brochure%2C%201936%20Pleasure%20Cruises%20to%20the%20West%20Indies-200.jpg",
        },
        {
          id: "Harcourt-Brace-and-Company",
          text: "Harcourt Brace and Company",
          thumbnail:
            "https://assets.paulrand.design/Works/Harcourt%20Brace%20and%20Company/Harcourt-Brace-and-Company-1957.svg",
        },
        {
          id: "Headline-Cigars",
          text: "Headline Cigars",
          thumbnail:
            "https://assets.paulrand.design/Works/Headline%20Cigars/Tin/Web/Headline%20Cigar%20tin%2001-200.jpg",
        },
        {
          id: "Helbros",
          text: "Helbros",
          thumbnail:
            "https://assets.paulrand.design/Works/Helbros/Helbros-1943.svg",
        },
        {
          id: "Holiday-Magazine",
          text: "Holiday Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Holiday%20Magazine/Web/Holiday%20magazine-200.jpg",
        },
        {
          id: "Hub-TV",
          text: "Hub TV",
          thumbnail:
            "https://assets.paulrand.design/Works/Hub%20TV/Hub-TV-1995.svg",
        },
      ],
    },
    {
      text: "I",
      children: [
        {
          id: "IBM",
          text: "IBM",
          thumbnail:
            "https://assets.paulrand.design/Works/IBM/Logos/IBM-8-bar.svg",
        },
        {
          id: "ideo-Design",
          text: "ideo Design",
          thumbnail:
            "https://assets.paulrand.design/Works/ideo%20Design/Ideo-Design-1991.svg",
        },
        {
          id: "IITRI",
          text: "IITRI",
          thumbnail:
            "https://assets.paulrand.design/Works/IITRI/IITRI-1964.svg",
        },
        {
          id: "Interfaith-Day",
          text: "Interfaith Day",
          thumbnail:
            "https://assets.paulrand.design/Works/Interfaith%20Day/Posters/Web/Interfaith%20Day%20Poster%201944%2001a-200.jpg",
        },
        {
          id: "International-Design-Conference-Aspen",
          text: "International Design Conference- Aspen",
          thumbnail:
            "https://assets.paulrand.design/Works/International%20Design%20Conference-%20Aspen/Posters/Web/Aspen%20Design%20Conference%20Poster%2001a-200.jpg",
        },
        {
          id: "Irwin-Union-Financial-Corporation",
          text: "Irwin Union Financial Corporation",
          thumbnail:
            "https://assets.paulrand.design/Works/Irwin-Sweeney-Miller%20Foundation/Annual%20Reports/1971/Web/Irwin-Sweeney-Miller%20Foundation%20Annual%20Report%201971-200.jpg",
        },
        {
          id: "Irwin-Sweeney-Miller-Foundation",
          text: "Irwin-Sweeney-Miller Foundation",
          thumbnail:
            "https://assets.paulrand.design/Works/Irwin%20Union%20Financial%20Corporation/Irwin-Financial-Corporation-1990.svg",
        },
        {
          id: "Isaac-Goldman-Company",
          text: "Isaac Goldman Company",
          thumbnail:
            "https://assets.paulrand.design/Works/Isaac%20Goldman%20Company/Web/Isaac%20Goldman%20Company%20Calendar%201942-200.jpg",
        },
      ],
    },
    {
      text: "J",
      children: [
        {
          id: "Jacqueline-Cochran",
          text: "Jacqueline Cochran",
          thumbnail:
            "https://assets.paulrand.design/Works/Jacqueline%20Cochran/Jacqueline-Cochran-1944-v1.svg",
        },
        {
          id: "James-E-Pepper-Kentucky-Bourbon",
          text: "James E Pepper Kentucky Bourbon",
          thumbnail:
            "https://assets.paulrand.design/Works/James%20E%20Pepper%20Kentucky%20Bourbon/James-E-Pepper-man-1951.svg",
        },
        {
          id: "Jazzways",
          text: "Jazzways",
          thumbnail:
            "https://assets.paulrand.design/Works/Jazzways/Web/Jazzways-200.jpg",
        },
        {
          id: "John-David-Stores",
          text: "John David Stores",
          thumbnail:
            "https://assets.paulrand.design/Works/John%20David/John-David-Stores-pre-1965.svg",
        },
      ],
    },
    {
      text: "K",
      children: [
        {
          id: "Kaiser-Frazer",
          text: "Kaiser-Frazer",
          thumbnail:
            "https://assets.paulrand.design/Works/Kaiser-Frazer/Kaiser-Frazer-Service-Station-pre-1965.svg",
        },
        {
          id: "Kaufmanns",
          text: "Kaufmann’s",
          thumbnail:
            "https://assets.paulrand.design/Works/Kaufmann%E2%80%99s/Kaufmanns-1948.svg",
        },
        {
          id: "Ken-Magazine",
          text: "Ken Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Ken%20magazine/Ken-magazine.svg",
        },
        {
          id: "Kenilworth-Press",
          text: "Kenilworth Press",
          thumbnail:
            "https://assets.paulrand.design/Works/Kenilworth%20Press/Web/Kenilworth%20Press-200.jpg",
        },
        {
          id: "Knopf",
          text: "Knopf",
          thumbnail:
            "https://assets.paulrand.design/Works/Knopf/Web/Knopf-%20Bell%20for%20Adano-200.jpg",
        },
      ],
    },
    {
      text: "L",
      children: [
        {
          id: "La-Palina-Cigars",
          text: "La Palina Cigars",
          thumbnail:
            "https://assets.paulrand.design/Works/La%20Palina%20Cigars/La-Palina-Cigars.svg",
        },
        {
          id: "Lee-Hats",
          text: "Lee Hats",
          thumbnail:
            "https://assets.paulrand.design/Works/Lee%20Hats/Lee-Hats-Rabbit-1947.svg",
        },
        {
          id: "Lovera-Cigars",
          text: "Lovera Cigars",
          thumbnail:
            "https://assets.paulrand.design/Works/Lovera%20Cigars/Advertising/Web/Lovera%20Give%20Dad%20Lovera%2001-200.jpg",
        },
        {
          id: "Lusterlite-American-Bemberg-Corp",
          text: "Lusterlite-American Bemberg Corp",
          thumbnail:
            "https://assets.paulrand.design/Works/Lusterlite-%20American%20Bemberg%20Corp/Web/Lusterlite%20Ad-200.jpg",
        },
      ],
    },
    {
      text: "M",
      children: [
        {
          id: "Maidenform",
          text: "Maidenform",
          thumbnail:
            "https://assets.paulrand.design/Works/Maidenform/Maidenform-1949.svg",
        },
        {
          id: "Monell-Chemical-Senses-Center",
          text: "Monell Chemical Senses Center",
          thumbnail:
            "https://assets.paulrand.design/Works/Monell%20Chemical%20Senses%20Center/Monell-Chemical-Senses-Center-1989.svg",
        },
        {
          id: "Morningstar",
          text: "Morningstar",
          thumbnail:
            "https://assets.paulrand.design/Works/Morningstar/Morningstar-1991.svg",
        },
        {
          id: "Mossberg-Company",
          text: "Mossberg & Company",
          thumbnail:
            "https://assets.paulrand.design/Works/Mossberg%20%26%20Company/Mossberg-and-Company-1987.svg",
        },
        {
          id: "Museum-of-Modern-Art",
          text: "Museum of Modern Art",
          thumbnail:
            "https://assets.paulrand.design/Works/Museum%20of%20Modern%20Art/Marcel%20Breuer%20Exhibit%20Poster/Web/Marcel%20Breuer%20Exhibit%20Poster-200.jpg",
        },
      ],
    },
    {
      text: "N",
      children: [
        {
          id: "National-Foundation-for-Infantile-Paralysis",
          text: "National Foundation for Infantile Paralysis",
          thumbnail:
            "https://assets.paulrand.design/Works/National%20Foundation%20for%20Infantile%20Paralysis/Web/National%20Foundation%20for%20Infantile%20Paralysis%2001-200.jpg",
        },
        {
          id: "National-Gallery-of-Art",
          text: "National Gallery of Art",
          thumbnail:
            "https://assets.paulrand.design/Works/National%20Gallery%20of%20Art/Splendor%20of%20Dresden%20Exhibit/Web/The%20Splendor%20of%20Dresden%20Poster%201978%2001-200.jpg",
        },
        {
          id: "National-Park-Service",
          text: "National Park Service",
          thumbnail:
            "https://assets.paulrand.design/Works/National%20Park%20Service/Boston%20National%20Historical%20Park%20Poster/Web/Boston%20National%20Historical%20Park%20Poster%2001-200.jpg",
        },
        {
          id: "New-York-Advertising-Co",
          text: "New York Advertising Co.",
          thumbnail:
            "https://assets.paulrand.design/Works/New%20York%20Subways%20Advertising%20Co/Subway%20Posters%20Score/Web/Subway%20Posters%20Score%2001-200.jpg",
        },
        {
          id: "NeXT-Computers",
          text: "NeXT Computers",
          thumbnail:
            "https://assets.paulrand.design/Works/NeXT/NeXT-Computers-1986.svg",
        },
        {
          id: "No-Way-Out-Movie",
          text: "No Way Out Movie",
          thumbnail:
            "https://assets.paulrand.design/Works/No%20Way%20Out%20Movie/Web/No%20Way%20Out%20Ad-200.jpg",
        },
        {
          id: "Norwalk-Cancer-Center",
          text: "Norwalk Cancer Center",
          thumbnail:
            "https://assets.paulrand.design/Works/Norwalk%20Cancer%20Center/Norwalk-Cancer-Center-1996.svg",
        },
        {
          id: "Nutri-Cola",
          text: "Nutri Cola",
          thumbnail:
            "https://assets.paulrand.design/Works/Nutri%20Cola/Nutri-Cola-1947.svg",
        },
        {
          id: "Nylast",
          text: "Nylast",
          thumbnail: "https://assets.paulrand.design/Works/Nylast/Nylast.svg",
        },
      ],
    },
    {
      text: "O",
      children: [
        {
          id: "Ohrbachs",
          text: "Ohrbachs",
          thumbnail:
            "https://assets.paulrand.design/Works/Ohrbachs/Ohrbach%27s-1945.svg",
        },
        {
          id: "Okasan-Securities-Company",
          text: "Okasan Securities Company",
          thumbnail:
            "https://assets.paulrand.design/Works/Okasan%20Securities%20Company/Okasan-Securities-Company-1991.svg",
        },
        {
          id: "Olivetti",
          text: "Olivetti",
          thumbnail:
            "https://assets.paulrand.design/Works/Olivetti/Web/Olivetti%2001-200.jpg",
        },
        {
          id: "Orpi",
          text: "Orpi",
          thumbnail:
            "https://assets.paulrand.design/Works/Orpi/Web/Orpi-970.jpg",
        },
      ],
    },
    {
      text: "P",
      children: [
        {
          id: "Pacific-Coast-Borax-Co",
          text: "Pacific Coast Borax Co",
          thumbnail:
            "https://assets.paulrand.design/Works/Pacific%20Coast%20Borax%20Co/Pacific-Coast-Borax-Co.svg",
        },
        {
          id: "Park-Tilford",
          text: "Park Tilford",
          thumbnail:
            "https://assets.paulrand.design/Works/Park%20Tilford/Web/Park%20Tilford%2001-970.jpg",
        },
        {
          id: "Pastore-DePamphilis-Rampone",
          text: "PDR (Pastore DePamphilis Rampone)",
          thumbnail:
            "https://assets.paulrand.design/Works/Pastore%20DePamphilis%20Rampone%20%28PDR%29/PDR-Pastore-DePamphilis-Rampone-1987.svg",
        },
        {
          id: "Penn-Brite-Papers",
          text: "Penn/Brite Papers",
          thumbnail:
            "https://assets.paulrand.design/Works/Penn%20Brite%20Papers/Web/Penn%20Brite%20Paper%20front-200.jpg",
        },
        {
          id: "Playtex",
          text: "Playtex",
          thumbnail:
            "https://assets.paulrand.design/Works/Playtex/Playtex-1941.svg",
        },
        {
          id: "PM-Magazine",
          text: "PM Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/PM%20Magazine/Web/PM%20Magazine%20ad-970.jpg",
        },
        {
          id: "Portfolio-Magazine",
          text: "Portfolio Magazine",
          thumbnail:
            "https://assets.paulrand.design/Works/Portfolio%20Magazine/Portfolio-Magazine.svg",
        },
        {
          id: "PowerPC-and-PowerOpen",
          text: "Power PC and Power Open",
          thumbnail:
            "https://assets.paulrand.design/Works/Power%20PC%20and%20Power%20Open/PowerOpen-1991-blue-red.svg",
        },
      ],
    },
    {
      text: "R",
      children: [
        {
          id: "Rainfair-Storm-Coats",
          text: "Rainfair Storm Coats",
          thumbnail:
            "https://assets.paulrand.design/Works/Rainfair%20Storm%20Coats/Rainfair-Coats-1944.svg",
        },
        {
          id: "RCA",
          text: "RCA",
          thumbnail:
            "https://assets.paulrand.design/Works/RCA/Web/RCA%2001-200.jpg",
        },
        {
          id: "Revlon",
          text: "Revlon",
          thumbnail:
            "https://assets.paulrand.design/Works/Revlon/Web/Revlon%2001-200.jpg",
        },
        {
          id: "Ringling-Brothers",
          text: "Ringling Brothers",
          thumbnail:
            "https://assets.paulrand.design/Works/Ringling%20Brothers/Web/Ringling%20Brothers%20comp-200.jpg",
        },
        {
          id: "Robert-Porter-Associates",
          text: "Robert Porter Associates",
          thumbnail:
            "https://assets.paulrand.design/Works/Robert%20Porter%20Associates/Web/Robert%20Porter%20Associates%20Stationery%20-%20Letterhead%2001-200.jpg",
        },
      ],
    },
    {
      text: "S",
      children: [
        {
          id: "Saybrook-RH-Macy",
          text: "Saybrook, RH Macy and Co 1935",
          thumbnail:
            "https://assets.paulrand.design/Works/Saybrook%2C%20RH%20Macy%20and%20Co%201935/Saybrook-RH-Macy-and-Co.svg",
        },
        {
          id: "Schenley-Gin",
          text: "Schenley Gin",
          thumbnail:
            "https://assets.paulrand.design/Works/Schenley%20Gin/Bottles/Web/Schenley%20Gin%20Bottle%20Labels-200.jpg",
        },
        {
          id: "Servador",
          text: "Servador",
          thumbnail:
            "https://assets.paulrand.design/Works/Servador/Servador-1996.svg",
        },
        {
          id: "Shell-Oil",
          text: "Shell Oil",
          thumbnail:
            "https://assets.paulrand.design/Works/Shell%20Oil/Web/Shell%20Oil%20study-200.jpg",
        },
        {
          id: "Shigeo-Fukuda",
          text: "Shigeo Fukuda",
          thumbnail:
            "https://assets.paulrand.design/Works/Shigeo%20Fukuda-%20Toys%20and%20Things%20Exhibit/Web/Shigeo%20Fukuda%20Toys%20and%20Things%20Exhibit%20Announcement%201967%2001-200.jpg",
        },
        {
          id: "Shur-Edge-Knives",
          text: "Shur-Edge Knives",
          thumbnail:
            "https://assets.paulrand.design/Works/Shur-Edge%20Knives/Shuredge-1947.svg",
        },
        {
          id: "Smith-Kline-French",
          text: "Smith, Kline & French",
          thumbnail:
            "https://assets.paulrand.design/Works/Smith%2C%20Kline%20%26%20French/Smith-Kline-and-French-Laboratories-1945.svg",
        },
        {
          id: "SOS-kinderdorf",
          text: "SOS Kinderdorf",
          thumbnail:
            "https://assets.paulrand.design/Works/SOS%20Kinderdorf/Web/SOS%20Kinderdorf%20Poster-200.jpg",
        },
        {
          id: "Stafford-Fabrics",
          text: "Stafford Fabrics",
          thumbnail:
            "https://assets.paulrand.design/Works/Stafford%20Fabrics/Stafford-Fabrics-1942.svg",
        },
        {
          id: "Strathmore-Paper",
          text: "Strathmore Paper",
          thumbnail:
            "https://assets.paulrand.design/Works/Strathmore%20Paper/Web/Strathmore%20Paper-200.jpg",
        },
      ],
    },
    {
      text: "T",
      children: [
        {
          id: "The-Limited",
          text: "The Limited",
          thumbnail:
            "https://assets.paulrand.design/Works/The%20Limited/The-Limited-1988.svg",
        },
        {
          id: "Thomas-Creighton",
          text: "Thomas Creighton Co",
          thumbnail:
            "https://assets.paulrand.design/Works/Thomas%20Creighton%20Co/Thomas-Creighton-Co.svg",
        },
        {
          id: "Tipton-Lakes",
          text: "Tipton Lakes",
          thumbnail:
            "https://assets.paulrand.design/Works/Tipton%20Lakes/Tipton-Lakes-1980.svg",
        },
        {
          id: "Todays-Woman",
          text: "Today‘s Woman",
          thumbnail:
            "https://assets.paulrand.design/Works/Today%27s%20Woman/Web/Todays%20Woman%201946-07-200.jpg",
        },
        {
          id: "Tri-Arts-Press",
          text: "Tri Arts Press",
          thumbnail:
            "https://assets.paulrand.design/Works/Tri%20Arts%20Press/Poster/Web/Tri%20Arts%20Press%20Poster-200.jpg",
        },
        {
          id: "Typomundus20",
          text: "Typomundus 20",
          thumbnail:
            "https://assets.paulrand.design/Works/Typomundus%2020/Web/Typomundus%2020%20Call%20for%20Entries%20Poster%2001-200.jpg",
        },
      ],
    },
    {
      text: "U",
      children: [
        {
          id: "UCLA",
          text: "UCLA",
          thumbnail:
            "https://assets.paulrand.design/Works/UCLA/1990%20UCLA%20Extension%2C%20Winter%20Quarter/Web/UCLA%20Extension%2C%20Winter%20Quarter%201990%2001-200.jpg",
        },
        {
          id: "Unknown",
          text: "Unknown",
          thumbnail:
            "https://assets.paulrand.design/Works/Unknown%20clients/Web/Asia%20cover%20sketch%201934-200.jpg",
        },
        {
          id: "United-Jewish-Appeal",
          text: "United Jewish Appeal",
          thumbnail:
            "https://assets.paulrand.design/Works/United%20Jewish%20Appeal/Web/United%20Jewish%20Appeal%2001-200.jpg",
        },
        {
          id: "United-Parcel-Service",
          text: "UPS (United Parcel Service)",
          thumbnail:
            "https://assets.paulrand.design/Works/United%20Parcel%20Service%20%28UPS%29/United-Parcel-Service-UPS-1961.svg",
        },
        {
          id: "University-of-Hartford",
          text: "University of Hartford",
          thumbnail:
            "https://assets.paulrand.design/Works/University%20of%20Hartford/Web/University%20of%20Hartford%20Poster%201989-200.jpg",
        },
        {
          id: "United-States-Satellite-Broadcasting",
          text: "USSB (United States Satellite Broadcasting)",
          thumbnail:
            "https://assets.paulrand.design/Works/United%20States%20Satellite%20Broadcasting%20%28USSB%29/US-Satellite-Broadcasting-USSB-1995.svg",
        },
      ],
    },
    {
      text: "V",
      children: [
        {
          id: "Vichy",
          text: "Vichy",
          thumbnail:
            "https://assets.paulrand.design/Works/Vichy/Web/Vichy-200.jpg",
        },
      ],
    },
    {
      text: "W",
      children: [
        {
          id: "Wallace-Puppets",
          text: "Wallace Puppets",
          thumbnail:
            "https://assets.paulrand.design/Works/Wallace%20Puppets/Wallace-Puppets-1938.svg",
        },
        {
          id: "Westinghouse",
          text: "Westinghouse",
          thumbnail:
            "https://assets.paulrand.design/Works/Westinghouse/Westinghouse-1960.svg",
        },
        {
          id: "William-H-Weintraub",
          text: "William H Weintraub",
          thumbnail:
            "https://assets.paulrand.design/Works/William%20H%20Weintraub/Web/Weintraub%20Gropius%20Ad%2001-970.jpg",
        },
        {
          id: "Wittenborn-Schultz",
          text: "Wittenborn Schultz",
          thumbnail:
            "https://assets.paulrand.design/Works/Wittenborn%20Schultz/Wittenborn%20Schultz%20Catalog/Web/Wittenborn%20Schultz%20Catalog%2000-200.jpg",
        },
      ],
    },
    {
      text: "X",
      children: [
        {
          id: "XGA",
          text: "XGA",
          thumbnail: "https://assets.paulrand.design/Works/XGA/XGA-1992.svg",
        },
      ],
    },
    {
      text: "Y",
      children: [
        {
          id: "Yale",
          text: "Yale",
          thumbnail:
            "https://assets.paulrand.design/Works/Yale/Yale-University-Press-1985-2010.svg",
        },
      ],
    },
    {
      text: "Z",
      children: [
        {
          id: "Zebra-Press",
          text: "Zebra Press",
          thumbnail:
            "https://assets.paulrand.design/Works/Zebra%20Press/Zebra-Press-pre-1965.svg",
        },
      ],
    },
  ],
  templateResult: formatNavNoCaption,
});
